import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Componentes
import Speaker from "components/speaker"
import Container from "components/container"
import { motion } from "framer-motion"

const StyledWebinar = styled.section`
  background: ${colors.navy__extra_dark};
  padding: 60px 0;
  color: white;
  text-align: center;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .title__content {
    text-align: center;
    margin-bottom: 45px;

    ${breakpoint.medium`
      margin-bottom: 90px;
    `}

    p:first-of-type:not(h1 + p) {
      color: ${colors.turquoise__light};
      font-weight: 700;
      margin-bottom: 8px;
    }

    h1 {
      max-width: 735px;
      margin: 0 auto;
    }
  }

  ul {
    padding: 0 8px 0 1rem;
    margin: 1em 0;
    list-style: disc;
    // list-style-position: inside;

    li {
      margin-bottom: 8px;

      p {
        display: inline;
      }
    }
  }
`

const Webinar = (props) => {
  const { content, webinarSpeakers } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h1">{children}</h2>
      ),
    },
  }

  return (
    <StyledWebinar id="webinar">
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8">
            <motion.div
              initial={{ y: 32, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="title__content"
            >
              {renderRichText(content, renderOptions)}
            </motion.div>
          </div>
        </div>

        <div className="row ">
          {webinarSpeakers.map((speaker, index) => (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="col-6 col-sm-6 col-lg-3 mb-4 mb-lg-0"
            >
              <Speaker {...speaker} />
            </motion.div>
          ))}
        </div>
      </Container>
    </StyledWebinar>
  )
}

Webinar.propTypes = {
  content: PropTypes.shape({
    raw: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  webinarSpeakers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      profilePicture: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
    })
  ).isRequired,
}

export default Webinar
