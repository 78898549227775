import React from "react"

// Libraries
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Container from "components/container"
import Carousel from "react-slick"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Stylesheets
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const StyledClientsLogos = styled.section`
  padding: 60px 0;
  color: ${(props) =>
    props.theme === "Dark" ? colors.white : colors.navy__extra_dark};

  ${breakpoints.medium`
    padding: 120px 0;
    `}

  .client-logos__no-carousel {
    .logo {
      margin-bottom: 109px;
      padding: 0 16px;
    }
  }

  .client-logos__carousel {
    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        > div {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 16px;
        }
      }
    }

    .slick-dots {
      li {
        width: 8px;
        height: 8px;

        &.slick-active {
          button {
            background-color: ${colors.grey__active};
          }
        }

        button {
          width: 100%;
          height: 100%;
          background-color: ${colors.grey__inactive};
          border-radius: 50%;

          &::before {
            content: none;
            display: none;
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 60px;

    ${breakpoints.medium`
      margin-bottom: 120px;
    `}
  }

  ${(props) =>
    props.theme === "Dark" &&
    css`
      background-color: ${colors.navy__extra_dark};
    `}
`

const ClientsLogos = (props) => {
  const { logos, theme, layout, showCarousel } = props

  const carouselSettings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: layout === "3 Columns" ? 3 : 4,
    autoplay: true,
    slidesToScroll: layout === "3 Columns" ? 3 : 4,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          infinite: true,
          slidesToScroll: 2,
        },
      },
    ],
  }

  if (showCarousel) {
    return (
      <StyledClientsLogos theme={theme}>
        <Container>
          <Carousel className="client-logos__carousel" {...carouselSettings}>
            {logos.map((imageData) => (
              <div className="logo" key={imageData}>
                <GatsbyImage
                  image={getImage(imageData)}
                  alt={getImage(imageData)}
                />
              </div>
            ))}
          </Carousel>
        </Container>
      </StyledClientsLogos>
    )
  }

  return (
    <StyledClientsLogos theme={theme}>
      <Container>
        <h2 className="h1 text-center title">
          We work with some{" "}
          <span className="color--turquoise__primary">amazing companies</span>
        </h2>

        <div className="client-logos__no-carousel">
          <div className="row justify-content-between align-items-center">
            {logos.map((imageData) => (
              <div
                className={
                  layout === "3 columns" ? "col-6 col-sm-4" : "col-6 col-sm-3"
                }
              >
                <div className="logo">
                  <GatsbyImage
                    image={getImage(imageData)}
                    alt={getImage(imageData)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </StyledClientsLogos>
  )
}

ClientsLogos.propTypes = {
  title: PropTypes.string.isRequired,
  logos: PropTypes.shape({ map: PropTypes.shape({}) }).isRequired,
  layout: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  showCarousel: PropTypes.bool.isRequired,
}

export default ClientsLogos
