import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Components
import Button from "components/button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Icons
import IconCaret from "assets/icons/icon-caret-right.inline.svg"

const StyledCard = styled.div`
  height: 100%;
  border-radius: ${(props) => (props.hasIcon ? "16px" : "0px 0px 16px 16px")};
  box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.2);
  overflow: hidden;

  .card__image {
    width: 100%;
    height: 208px;
  }

  .card__content {
    width: 100%;
    height: calc(100% - 208px);
    padding: ${(props) => (props.hasIcon ? "32px" : "24px")};

    ${breakpoint.medium`
      padding: ${(props) => (props.hasIcon ? "56px" : "32px 40px 40px 40px")};
    `}

    .icon {
      margin-top: 24px;
      margin-bottom: 24px;

      ${breakpoint.medium`
        margin-bottom: 40px;
      `}
    }
    h3,
    h2 {
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      margin-bottom: 24px;
      color: ${colors.silver__dark};
    }

    a {
      width: 100%;
      justify-content: center;
    }
  }
`

const Card = (props) => {
  const { image, icon, content, callToAction } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h2">{children}</h3>
      ),
    },
  }

  return (
    <StyledCard hasIcon={icon !== null} className="d-flex flex-wrap bg--white">
      {image && (
        <GatsbyImage
          className="card__image flex-shrink-0"
          image={getImage(image)}
          alt=""
        />
      )}
      <div className="card__content d-flex flex-wrap">
        <div>
          {icon && (
            <img src={icon.file.url} className="icon" height="32" alt="" />
          )}

          {renderRichText(content, renderOptions)}
        </div>

        {callToAction && (
          <Button
            type="link"
            className="align-self-end align-items-center svg-stroke--turquoise__primary svg-hover--stroke-navy__dark"
            level={callToAction.appearance.toLowerCase()}
            external={callToAction.external}
          >
            {callToAction.label}
            <IconCaret className="ms-2" />
          </Button>
        )}
      </div>
    </StyledCard>
  )
}

Card.propTypes = {
  image: PropTypes.instanceOf(PropTypes.shape({})),
  icon: PropTypes.instanceOf(PropTypes.shape({})),
  content: PropTypes.shape({ raw: PropTypes.string.isRequired }).isRequired,
  callToAction: PropTypes.shape({
    appearance: PropTypes.oneOf(["Primary", "Secondary", "Tertiary"])
      .isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    external: PropTypes.bool.isRequired,
  }),
}

Card.defaultProps = {
  image: null,
  icon: null,
  callToAction: null,
}

export default Card
