import React, { useState, useRef } from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Container from "components/container"

const StyledQuotesCarousel = styled.section`
  padding: 80px 0;
  background-color: ${colors.navy__extra_dark};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .quotes__carousel {
    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        > div {
          height: 100%;
        }
      }

      .quote {
        height: 100%;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;

        > div {
          width: 100%;
          flex-shrink: 0;
        }

        p {
          white-space: normal;
        }
      }
    }
  }

  .carousel__controls {
    max-width: 384px;
    margin-left: auto;
    margin-right: auto;

    .controls__pictures {
      height: 96px;
    }

    .bullet {
      width: 64px;
      height: 64px;
      padding: 4px;
      margin-right: 16px;
      border-radius: 50%;
      border: 2px solid ${colors.silver__light};
      opacity: 0.6;
      transition: all 0.3s ease;

      &:last-child {
        margin-right: 0;
      }

      ${breakpoint.medium`
          margin-right: 32px;
        `}

      &:hover {
        opacity: 1;
      }

      &.active {
        width: 96px;
        height: 96px;
        opacity: 1;
        border-color: ${colors.turquoise__primary};
      }
    }
  }
`

const QuotesCarousel = (props) => {
  // Props
  const { quotes } = props

  // Hooks
  const ref = useRef()

  // State
  const [activeIndex, setActiveIndex] = useState(0)

  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    fade: true,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
  }

  const goTo = (index) => {
    if (ref.current) {
      ref.current.slickGoTo(index)
    }
  }

  return (
    <StyledQuotesCarousel id="quotes-carousel">
      <Container>
        <Slider
          className="quotes__carousel"
          ref={(slider) => {
            ref.current = slider
          }}
          {...carouselSettings}
        >
          {quotes.map((quote) => (
            <div className="quote text-center" key={quote.quote.quote}>
              <div>
                <GatsbyImage
                  image={getImage(quote.logo)}
                  className="mb-4 mb-md-5"
                  alt=""
                />
              </div>

              <p className="h1 mb-3 mb-md-4 font-weight--700">
                {quote.quote.quote}
              </p>

              <div className="align-self-end">
                <p className="h3 text--large mb-1 color--turquoise__primary">
                  <b>{quote.author}</b>
                </p>

                <p className="text--large color--silver__light">
                  {quote.authorsCompany}
                </p>
              </div>
            </div>
          ))}
        </Slider>

        <div className="carousel__controls d-flex align-items-center justify-content-center mt-4 mt-md-5">
          <div className="controls__pictures d-flex align-items-center">
            {quotes.map((quote, index) => (
              <button
                type="button"
                className={`bullet ${index === activeIndex && "active"}`}
                onClick={() => goTo(index)}
              >
                <GatsbyImage
                  image={getImage(quote.authorsProfilePicture)}
                  alt=""
                />
              </button>
            ))}
          </div>
        </div>
      </Container>
    </StyledQuotesCarousel>
  )
}

QuotesCarousel.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      authorsProfilePicture: PropTypes.shape({}).isRequired,
      authorsCompany: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default QuotesCarousel
