import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Utils
import breakpoint from "utils/breakpoints"
import { colors } from "utils/variables"

// Component
import Button from "components/button/"
import BackgroundImage from "components/background-image"

// Background icon
// import BackgroundLeft from "assets/icons/content-with-image/background-left.inline.svg"
import BackgroundFull from "assets/icons/content-with-image/background-full-width.inline.svg"

const StyledContentWithImage = styled.section`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .content__image {
    width: 100%;
    aspect-ratio: 1;
  }

  .content__content {
    padding: 32px 16px;

    ${breakpoint.small`
      padding: 0;
    `}

    p {
      margin: 1em 0;
    }

    ul {
      padding: 0 8px 0 1rem;
      margin: 1em 0;
      list-style: disc;
      // list-style-position: inside;

      li {
        margin-bottom: 8px;

        p {
          display: inline;
        }
      }
    }

    .button {
      margin-top: 24px;
    }
  }

  ${breakpoint.small`
      ${(props) =>
        props.imageStyle === "On the left" ||
        props.imageStyle === "On the right"
          ? css`
              // On the left styles
              .content__image {
                width: 50%;
                order: ${(props) =>
                  props.imageStyle === "On the right" ? "1" : "0"};
              }

              .content__content {
                width: 50%;
                order: ${(props) =>
                  props.imageStyle === "On the right" ? "0" : "1"};
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 80px 0;

                > div {
                  max-width: 448px;
                  width: 64%;
                  margin-right: 32px;

                  ${breakpoint.large`
                    margin-right: calc((100vw - 1120px) / 2);
                  `}
                }
              }
            `
          : css`
              // Full-width styles
              padding: 80px 0;

              ${breakpoint.large`
                padding: 120px 0;
              `}

              .content__image {
                height: 60%;
                position: absolute !important;
                top: 0;
                right: 0;
                left: 0;
                z-index: -1;
              }

              .content__content {
                max-width: 544px;
                width: 50%;
                padding: 32px;
                margin-left: 32px;
                background-color: ${colors.white};
                border-radius: 16px;
                box-shadow: 0px 7px 14px rgba(147, 159, 171, 0.27);

                ${breakpoint.large`
                  padding: 56px;
                  margin-left: calc((100vw - 1120px) / 2);
                `}

                .background-fullwidth {
                  svg {
                    position: absolute;
                    top: 60%;
                    right: 0;
                    z-index: 1;
                  }
                }
              }
            `}
    `}
`

const ContentWithImage = (props) => {
  const { content, cta, image, imageFullWidth, imageStyle, anchorId } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h1 mb-2">{children}</h2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text--large">{children}</p>
      ),
    },
  }

  return (
    <StyledContentWithImage
      id={anchorId || "contentWithImage"}
      imageStyle={imageStyle}
    >
      {(image || imageFullWidth) && (
        <BackgroundImage
          className="content__image"
          image={image || imageFullWidth}
        />
      )}

      <div className="content__content">
        <div>
          {renderRichText(content, renderOptions)}

          {cta && (
            <Button
              className="button"
              type="link"
              level={cta.appearance.toLowerCase()}
              to={cta.url}
              external={cta.external}
            >
              {cta.label}
            </Button>
          )}
        </div>

        {imageStyle === "Full width" ? (
          <div className="background-fullwidth d-none d-lg-block">
            <BackgroundFull />
          </div>
        ) : (
          <div className="d-none" />
        )}
      </div>
    </StyledContentWithImage>
  )
}

ContentWithImage.propTypes = {
  content: PropTypes.shape({ raw: PropTypes.string.isRequired }).isRequired,
  cta: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    appearance: PropTypes.oneOf(["Primary", "Secondary", "Tertiary"]),
    external: PropTypes.bool,
  }),
  image: PropTypes.instanceOf(PropTypes.shape({})),
  imageFullWidth: PropTypes.instanceOf(PropTypes.shape({})),
  imageStyle: PropTypes.oneOf(["On the left", "On the right", "Full width"])
    .isRequired,
  anchorId: PropTypes.string,
}

ContentWithImage.defaultProps = {
  cta: null,
  image: null,
  imageFullWidth: null,
  anchorId: null,
}

export default ContentWithImage
