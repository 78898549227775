import React from "react"

// Utils
import { colors } from "utils/variables"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { motion } from "framer-motion"

// Components
import Container from "components/container"

const StyledContentWithTwoColumns = styled.section`
  padding: 120px 0;
  color: ${colors.navy__extra_dark};

  .title {
    margin-bottom: 40px;
    text-align: center;
  }

  ul {
    padding: 0 8px 0 1rem;
    margin: 1em 0;
    list-style: disc;
    // list-style-position: inside;

    li {
      margin-bottom: 8px;

      p {
        display: inline;
      }
    }
  }

  a {
    text-decoration: underline;
  }
`

const ContentWithTwoColumns = (props) => {
  const { title, firstColumn, secondColumn } = props

  return (
    <StyledContentWithTwoColumns id="contentWithColumns">
      <Container>
        <motion.div
          initial={{ y: 32, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          className="text--large"
        >
          <h2 className="title h1">{title}</h2>

          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              {renderRichText(firstColumn)}
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              {renderRichText(secondColumn)}
            </div>
          </div>
        </motion.div>
      </Container>
    </StyledContentWithTwoColumns>
  )
}

ContentWithTwoColumns.propTypes = {
  title: PropTypes.string.isRequired,
  firstColumn: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
  secondColumn: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
}
export default ContentWithTwoColumns
