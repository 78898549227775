import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Card from "components/card"
import { colors, fonts } from "utils/variables"

const StyledContentWithCards = styled(motion.section)`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .content {
    p {
      b:not(h2 + p > b) {
        margin-bottom: 4px;
        font-family: ${fonts.secondary};
        color: ${colors.turquoise__primary};
        font-size: 16px;
        line-height: 1.1em;
      }
    }

    .h1,
    .h2 {
      margin-bottom: 12px;
    }

    .h1 + p,
    .h2 + p {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .cards {
    margin-top: 40px;
  }

  ul {
    padding: 0 8px 0 1rem;
    margin: 1em 0;
    list-style: disc;
    // list-style-position: inside;

    li {
      margin-bottom: 8px;

      p {
        display: inline;
      }
    }
  }

  a {
    text-decoration: underline;
  }
`

const ContentWithCards = (props) => {
  const { content, cards, anchorId } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h2 maxi">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h2 className="h1">{children}</h2>
      ),
    },
  }

  return (
    <StyledContentWithCards
      id={anchorId || "contentWithCards"}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ staggerChildren: 0.1 }}
    >
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-md-9">
            <div className="content text-center">
              {renderRichText(content, renderOptions)}
            </div>
          </div>
        </div>

        <div className="cards row justify-content-center">
          {cards &&
            cards.map((card, index) => (
              <motion.div
                key={card.title}
                className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0"
                initial={{ y: 32, scale: 0.95 }}
                animate={{ y: 0, scale: 1 }}
                viewport={{ once: true }}
                transition={{
                  delay: index * 0.1,
                }}
              >
                <Card {...card} />
              </motion.div>
            ))}
        </div>
      </Container>
    </StyledContentWithCards>
  )
}

ContentWithCards.propTypes = {
  content: PropTypes.shape({ raw: PropTypes.string.isRequired }).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.instanceOf(PropTypes.shape({})),
      icon: PropTypes.instanceOf(PropTypes.shape({})),
      content: PropTypes.shape({ raw: PropTypes.string.isRequired }).isRequired,
      callToAction: PropTypes.shape({
        appearance: PropTypes.oneOf(["Primary", "Secondary", "Tertiary"])
          .isRequired,
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        external: PropTypes.bool.isRequired,
      }),
    })
  ).isRequired,
  anchorId: PropTypes.string,
}

ContentWithCards.defaultProps = {
  anchorId: null,
}

export default ContentWithCards
