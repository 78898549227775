import React, { useState, useEffect } from "react"

// Libraries
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { motion } from "framer-motion"

// Components
import Container from "components/container/"
import Button from "components/button"

// Utils
import breakpoint from "utils/breakpoints/"

// Icons
import IconPattern from "assets/icons/components/marketing-hero/icon-pattern.inline.svg"
import HeroArrow from "components/hero-arrow"

const StyledMarketingHero = styled(motion.section)`
  max-height: -webkit-fill-available;
  min-height: ${(props) =>
    props.responsiveHeight ? "344px" : "calc(100vh - 62px - 58px);"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  ${breakpoint.medium`
    min-height: ${(props) =>
      props.responsiveHeight ? "440px" : "calc(100vh - 96px - 58px)"};
    padding: 64px 0;
  `}

  ${(props) =>
    props.image &&
    css`
      background-image: linear-gradient(
          179.92deg,
          rgba(0, 37, 72, 0.2) -9.69%,
          rgba(0, 37, 72, 0.805406) 61.43%,
          #002548 84.29%
        ),
        url(${(props) => props.image});
      background-size: cover;
      background-position: top center;
    `}

  ${Container} {
    position: relative;
    z-index: 2;
  }

  .hero__background-image {
    max-height: -webkit-fill-available;
    min-height: 100vh;
    width: 100%;
  }

  // Prevents from overflow scrolling
  iframe {
    height: 325px;
    display: flex;
    aling-items
    overflow: hidden !important;
  }

  .hero__content {
    .buttons {
      a {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
          margin: 0;
        }

        ${breakpoint.small`
          width: auto;
          margin-right: 16px;
          margin-bottom: 0;
        `}
      }
    }
  }

  .hero__pattern {
    position: absolute;
    bottom: 3vh;
    left: 0;
    z-index: 1;
  }
`

const MarketingHero = (props) => {
  const {
    content,
    callToActions,
    image,
    imageStyle,
    pardotForm,
    formStyle,
    responsiveHeight,
  } = props

  // State
  const [nextSection, setNextSection] = useState(null)

  useEffect(() => {
    const sections = document.querySelectorAll("main section")

    if (sections[1]) {
      setNextSection(sections[1].id)
    }
  }, [])

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="h1 maxi mb-4">{children}</h1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text--large">{children}</p>
      ),
    },
  }

  /**
   * Hero with full-width image
   */
  if (imageStyle === "Full width w/ text centered") {
    return (
      <StyledMarketingHero
        image={image.file.url}
        responsiveHeight={responsiveHeight}
        className="color--white"
      >
        <Container>
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-12 col-sm-6 col-md-7">
              <motion.div
                initial={{ y: 32, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="hero__content"
              >
                {renderRichText(content, renderOptions)}
                {callToActions && (
                  <div className="buttons d-flex justify-content-center flex-wrap flex-sm-nowrap mt-4">
                    {callToActions.map((button) => (
                      <Button
                        type="link"
                        to={button.url}
                        level={button.appearance.toLowerCase()}
                        external={button.external}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                )}
                {pardotForm && formStyle === "On the left" && (
                  <div
                    className="mt-4"
                    dangerouslySetInnerHTML={{
                      __html: pardotForm.embedCode.embedCode,
                    }}
                  />
                )}
              </motion.div>
            </div>
          </div>
        </Container>

        <HeroArrow
          link={`#${nextSection}`}
          className="svg--fill-navy__extra_dark"
          fill="svg--fill-white svg-hover--fill-turquoise__primary"
        />
      </StyledMarketingHero>
    )
  }

  /**
   * Hero w/ Form on the Right
   */
  if (pardotForm && formStyle === "On the right") {
    return (
      <StyledMarketingHero className="bg--silver__extra_pale">
        <Container>
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-6">
              <motion.div
                initial={{ y: -32, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="hero__content"
              >
                {renderRichText(content, renderOptions)}

                {callToActions && (
                  <div className="buttons d-flex flex-wrap mt-4">
                    {callToActions.map((button) => (
                      <Button
                        type="link"
                        to={button.url}
                        level={button.appearance.toLowerCase()}
                        external={button.external}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                )}
              </motion.div>
            </div>

            <div className="col-12 col-sm-5 col-md-6">
              <div
                className="hero__form"
                dangerouslySetInnerHTML={{
                  __html: pardotForm.embedCode.embedCode,
                }}
              />
            </div>
          </div>
        </Container>

        {pardotForm && formStyle === "On the right" && (
          <motion.div
            initial={{
              x: -100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              delay: 0.3,
            }}
            className="d-none d-sm-block hero__pattern"
          >
            <IconPattern />
          </motion.div>
        )}

        <HeroArrow link={`#${nextSection}`} />
      </StyledMarketingHero>
    )
  }

  return (
    <StyledMarketingHero className="bg--silver__extra_pale">
      <Container>
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-6">
            <motion.div
              initial={{ y: -32, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="hero__content"
            >
              {renderRichText(content, renderOptions)}

              {callToActions && (
                <div className="buttons d-flex flex-wrap mt-4">
                  {callToActions.map((button) => (
                    <Button
                      type="link"
                      to={button.url}
                      level={button.appearance.toLowerCase()}
                      external={button.external}
                    >
                      {button.label}
                    </Button>
                  ))}
                </div>
              )}

              {pardotForm && formStyle === "On the left" && (
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: pardotForm.embedCode.embedCode,
                  }}
                />
              )}
            </motion.div>
          </div>

          <div className="d-none d-sm-block col-12 col-sm-6">
            <div className="hero__image">
              <GatsbyImage image={getImage(image)} alt="" />
            </div>
          </div>
        </div>
      </Container>

      <HeroArrow link={`#${nextSection}`} />
    </StyledMarketingHero>
  )
}

MarketingHero.propTypes = {
  content: PropTypes.shape({ raw: PropTypes.string.isRequired }).isRequired,
  callToActions: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      appearance: PropTypes.oneOf(["Primary", "Secondary", "Tertiary"]),
      external: PropTypes.bool,
    })
  ),
  image: PropTypes.shape({
    gatsbyImageData: PropTypes.instanceOf(PropTypes.shape({})),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  imageStyle: PropTypes.oneOf([
    "On the right",
    "Full width",
    "Full width w/ text centered",
  ]),
  pardotForm: PropTypes.string,
  formStyle: PropTypes.oneOf(["On the left", "On the right"]),
  responsiveHeight: PropTypes.bool,
}

MarketingHero.defaultProps = {
  callToActions: null,
  imageStyle: "On the right",
  pardotForm: null,
  formStyle: "On the left",
  responsiveHeight: false,
}

export default MarketingHero
