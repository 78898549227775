import React from "react"

// Libraries
import PropTypes from "prop-types"
import { graphql } from "gatsby"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"
import VideoShowcase from "components/video-showcase"
import DownloadResource from "components/download-resource"
import MarketingHero from "components/marketing-hero"
import ContentWithTwoColumns from "components/content-with-2-columns"
import Webinar from "components/webinar"
import ContentWithImage from "components/content-with-image"
import Quote from "components/quote"
import ContentWithCards from "components/content-with-cards"
import ContentWithForm from "components/content-with-form"
import QuotesCarousel from "components/quotes-carousel"
import ClientsLogos from "components/clients-logos"

const MarketingLanding = (props) => {
  // Data
  const {
    data: { contentfulMarketingLanding: data },
  } = props

  const { title, seoDescription, sections, showNewsletterForm } = data

  // Array of Components
  const components = []

  sections.forEach((section) => {
    const { __typename: type } = section

    switch (type) {
      case "ContentfulHero":
        components.push(<MarketingHero {...section} />)
        break

      case "ContentfulQuotes":
        components.push(<Quote {...section} />)
        break

      case "ContentfulContentWCards":
        components.push(<ContentWithCards {...section} />)
        break

      case "ContentfulVideoShowcases":
        components.push(<VideoShowcase {...section} />)
        break

      case "ContentfulDownloadResource":
        components.push(<DownloadResource {...section} />)
        break
      case "ContentfulContentWImage":
        components.push(<ContentWithImage {...section} />)
        break

      case "ContentfulContentW2Columns":
        components.push(<ContentWithTwoColumns {...section} />)
        break

      case "ContentfulWebinarSection":
        components.push(<Webinar {...section} />)
        break

      case "ContentfulContentWithForm":
        components.push(<ContentWithForm {...section} />)
        break

      case "ContentfulQuotesCarousel":
        components.push(<QuotesCarousel {...section} />)
        break

      case "ContentfulClientLogos":
        components.push(<ClientsLogos {...section} />)
        break

      default:
        break
    }
  })

  return (
    <Layout showNewsletter={showNewsletterForm}>
      <SEO title={title} description={seoDescription.seoDescription} />

      {components.map((component) => (
        <div key={component}>{component}</div>
      ))}
    </Layout>
  )
}

MarketingLanding.propTypes = {
  data: PropTypes.shape({
    contentfulMarketingLanding: PropTypes.shape({
      title: PropTypes.string.isRequired,
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string,
      }),
      sections: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.shape({})))
        .isRequired,
      showNewsletterForm: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
}

export default MarketingLanding

export const query = graphql`
  query ($id: String) {
    contentfulMarketingLanding(id: { eq: $id }) {
      title
      showNewsletterForm
      seoDescription {
        seoDescription
      }
      sections {
        __typename

        ... on ContentfulHero {
          ...MarketingHero
        }

        ... on ContentfulContentWCards {
          ...ContentWithCards
        }

        ... on ContentfulVideoShowcases {
          ...VideoShowcase
        }

        ... on ContentfulDownloadResource {
          ...DownloadResource
        }

        ... on ContentfulContentW2Columns {
          title
          firstColumn {
            raw
          }
          secondColumn {
            raw
          }
        }

        ... on ContentfulWebinarSection {
          title
          content {
            raw
          }
          webinarSpeakers {
            name
            role
            profilePicture {
              gatsbyImageData(width: 184, height: 184, quality: 100)
            }
          }
        }

        ... on ContentfulContentWImage {
          title
          imageStyle
          content {
            raw
          }
          cta {
            ...CallToAction
          }
          image {
            gatsbyImageData(width: 704, quality: 100)
          }
          imageFullWidth {
            gatsbyImageData(width: 1440, quality: 100)
          }
          anchorId
        }

        ... on ContentfulQuotes {
          ...Quote
        }

        ... on ContentfulContentWithForm {
          ...ContentWithForm
        }

        ... on ContentfulQuotesCarousel {
          ...QuotesCarousel
        }

        ... on ContentfulClientLogos {
          ...ClientLogos
        }
      }
    }
  }
`
