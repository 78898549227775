import React from "react"

// Libraries
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledSpeaker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Speaker = (props) => {
  const { name, role, profilePicture } = props

  return (
    <StyledSpeaker>
      <GatsbyImage image={getImage(profilePicture)} />
      <h3 className="font-weight--700 mt-3 mb-2">{name}</h3>
      <p className="rol color--silver__light">{role}</p>
    </StyledSpeaker>
  )
}

Speaker.propTypes = {
  profilePicture: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
}

export default Speaker
