import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"

// Icons
import IconPlay from "assets/icons/icon-play.inline.svg"

const StyledVideoShowcase = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  ${Container} {
    max-width: 918px;
  }

  .showcase__content {
    margin: 16px 0;
    p {
      margin: 16px 0;
    }

    .h1 {
      margin: 8px 0;
    }
  }

  .video-wrapper {
    aspect-ratio: 16 / 9;
    position: relative;
    margin-top: 46px;
    border-radius: 8px;
    overflow: hidden;

    .placeholder {
      position: relative;

      button {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        ${breakpoint.small`
          width: 60px;
          height: 60px;
        `}
      }
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  a {
    color: ${colors.turquoise__primary};
    font-weight: 700;
    text-decoration: underline;
  }
`

const VideoShowcase = (props) => {
  // Data
  const { content, videoPlaceholder, videoUrl, anchorId } = props

  // State
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  /**
   * Handles click on placeholder
   */
  const handleClick = () => {
    setShowPlaceholder(false)
  }

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h1">{children}</h2>
      ),
    },
  }

  return (
    <StyledVideoShowcase
      id={anchorId || "videoShowcase"}
      className="bg--navy__extra_dark color--white"
    >
      <Container>
        {content && (
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-9">
              <div className="showcase__content text-center">
                {renderRichText(content, renderOptions)}
              </div>
            </div>
          </div>
        )}

        <motion.div
          initial={{ y: 32, scale: 0.95 }}
          whileInView={{ y: 0, scale: 1 }}
          viewport={{ once: true }}
          className="video-wrapper"
        >
          {showPlaceholder ? (
            <div className="placeholder">
              <GatsbyImage image={getImage(videoPlaceholder)} alt="" />
              <button type="button" className="d-flex" onClick={handleClick}>
                <IconPlay />
              </button>
            </div>
          ) : (
            <iframe
              src={`https://www.youtube.com/embed/${videoUrl}?autoplay=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </motion.div>
      </Container>
    </StyledVideoShowcase>
  )
}

VideoShowcase.propTypes = {
  content: PropTypes.shape({
    raw: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
  }),
  videoPlaceholder: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
  videoUrl: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
}

VideoShowcase.defaultProps = {
  content: null,
  anchorId: null,
}

export default VideoShowcase
