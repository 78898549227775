import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Utils
import { colors } from "utils/variables/"

// Icons
import IconQuote from "assets/icons/components/quote/icon-quote.inline.svg"

const StyledQuote = styled.section`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  padding: 120px 0;
  overflow: hidden;

  ${breakpoint.small`
    max-height: 488px;
    min-height: auto;
    heigth: auto;
  `}

  .quote__image {
    padding: 2px;
    border: 2px solid ${colors.turquoise__primary};
    border-radius: 50%;
  }

  .quote__icon {
    max-width: 408px;
    width: 60%;
    height: auto;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

const Quote = (props) => {
  const { quote, author, authorsProfilePicture, authorsCompany, anchorId } =
    props

  return (
    <StyledQuote
      className="bg--navy__extra_dark color--white"
      id={anchorId || "quote"}
    >
      <Container>
        <div className="row">
          <div className="col-12 col-sm-8 col-md-9">
            <div className="quote">
              <div className="quote__content">
                {quote && (
                  <p className="h2 mb-3 font-weight--700">{quote.quote}</p>
                )}

                <div className="d-flex align-items-center">
                  <div className="quote__image me-3">
                    <GatsbyImage
                      image={getImage(authorsProfilePicture)}
                      alt=""
                      imgStyle={{ borderRadius: "50%", overflow: "hidden" }}
                      width={58}
                    />
                  </div>

                  <div>
                    <p className="text--large d-block mb-1 color--turquoise__primary font-weight--700">
                      {author}
                    </p>

                    <p>
                      <span className="d-block color--silver__light">
                        {authorsCompany}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <IconQuote className="quote__icon" />
    </StyledQuote>
  )
}

Quote.propTypes = {
  quote: PropTypes.shape({ quote: PropTypes.string.isRequired }).isRequired,
  author: PropTypes.string.isRequired,
  authorsProfilePicture: PropTypes.shape({}).isRequired,
  authorsCompany: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
}

Quote.defaultProps = {
  anchorId: null,
}

export default Quote
